@charset "utf-8";
/* CSS Document */
/*checkbox*/

input[type='checkbox'] {
   width: 16px;
   height: 16px;
   -webkit-appearance: none;
   -moz-appearance: none;
   -o-appearance: none;
   appearance: none;
   outline: none;
   box-shadow: none;
   background: url(../images/checkbox_off.png) no-repeat;
   transition: background 0.15s;
   -webkit-transition: background 0.15s;
   vertical-align: middle;
}




.loader {
   position: fixed;
   left: 0;
   top: 0;
   height: 100%;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   z-index: 10000;
}

.download_icon_google {
   -webkit-appearance: none;
   -moz-appearance: none;
   -o-appearance: none;
   background: url(../images/applink_google_basic.png) no-repeat;
   width : 120px;
   height : 35px;
}

.download_icon_google:hover {
   cursor: pointer;
   -webkit-appearance: none;
   -moz-appearance: none;
   -o-appearance: none;
   background: url(../images/applink_google_hover.png) no-repeat;
   transition: background 0.3s;
   -webkit-transition: background 0.3s;
   width : 120px;
   height : 35px;
}


.download_icon_apple {
   -webkit-appearance: none;
   -moz-appearance: none;
   -o-appearance: none;
   background: url(../images/applink_appstore_basic.png) no-repeat;
   width : 120px;
   margin-left : 5px;
   height : 35px;
}

.download_icon_apple:hover {
   cursor: pointer;
   -webkit-appearance: none;
   -moz-appearance: none;
   -o-appearance: none;
   background: url(../images/applink_appstore_hover.png) no-repeat;
   transition: background 0.3s;
   -webkit-transition: background 0.3s;
   width : 120px;
   height : 35px;
   margin-left : 5px;
}

.app_link_div {
   display : flex;
}

input[type='checkbox']:checked {
   -webkit-appearance: none;
   -moz-appearance: none;
   -o-appearance: none;
   appearance: none;
   box-shadow: none;
   background: url(../images/checkbox_on.png) no-repeat;
}

/*radio*/

input[type='radio'] {
   width: 20px;
   height: 20px;
   vertical-align: middle;
   -webkit-appearance: none;
   -moz-appearance: none;
   -o-appearance: none;
   appearance: none;
   outline: none;
   box-shadow: none;
   background: url(../images/radio_off.png) no-repeat;
   transition: background 0.15s;
   -webkit-transition: background 0.15s;
}

input[type='radio']:checked {
   -webkit-appearance: none;
   -moz-appearance: none;
   -o-appearance: none;
   appearance: none;
   box-shadow: none;
   background: url(../images/radio_on.png) no-repeat;
}

.write_message {
   width: 670px;
   margin-top: 45px;
}

/*input
   width: 300px;
   border: 1px solid #d5d5d5;
*/
input[type='text'] {
   border-radius: 7px;
   border: 1px solid #d5d5d5;
   height: 40px;
   padding: 0 8px;
   font-family: 'Noto Sans KR';
   font-size: 15px;
}

.input_txt_sm {
   width: 180px !important;
}

.signup_password::placeholder {
   font-size: 14px;
}
/*
   width: 300px;
*/
input[type='password'] {
   box-sizing: border-box;
   border: 1px solid #d5d5d5;
   border-radius: 7px;
   height: 40px;
   padding: 0 8px;
   font-family: 'Noto Sans KR';
   font-size: 15px;
}

input::placeholder {
   color: #bdbdbd;
   font-family: 'Noto Sans KR';
   font-size: 15px;
}

/*select*/
select {
   width: 155px;
   height: 40px;
   font-family: 'Noto Sans KR';
   font-size: 15px;
   padding: 0 8px;
 /*  border: 1px solid #d5d5d5; */
   margin-right: 5px;
}

select.write_select {
   width: 120px !important;
}

select + .input_btn {
   margin-left: 0;
}

/*********inner**********/

.container {
   position: relative;
   width: 1200px;
   margin: 0 auto;
}

.board_close_img:hover {
   cursor: pointer;
}

.container > section {
   margin-bottom: 40px;
}


/**************버튼*****************/

/** 운행일지 출력 버튼 */

.exportRunHistImg {
   position: absolute;
   z-index: 100;
   margin-top: -2px;
   margin-left: 9px;
}

.exportRunHist {
   border-radius: 6px;
   /* background-color: #6b00af; */
   background: linear-gradient(to right, #9520df, #5808fb);
   width: 144px;
   height: 32px;
   position: relative;
   bottom: 2px;
   font-family: 'Noto Sans KR_Bold, sans-serif', serif;
   font-size: 15px !important;
   color: #eee !important;
   padding: 5px 11px;
   padding-left: 33px;
   font-weight: 700;
   border: 1px #ffffff;

   /*bg: #ffffff (  opacity: 20%) or #3d6cb4 font: NotosansKR - Bold - 15 pt*/
}
.exportRunHist:hover {
   /* background-color: #56008d; */
   background: linear-gradient(to right, #7d18bd, #4e09dc);
}


/*입력폼*/
.input {
   width: 100%;
   border-top: 1px solid #4e4e4e;
   border-bottom: 1px solid #eeeeee;
   vertical-align: middle;
}

.msg_textarea::placeholder {
   font-size: 15px;
   color: #c1c1c1;
}

.write_board::placeholder {
   font-size: 15px;
   color: #c1c1c1;
   padding-top: 5px;
}

.msg_textarea {
   border: 1px solid #e1e1e1;
   resize: none;
   width: 552px !important;
   margin: 10px 0;
   font-family: 'Noto Sans KR', serif;
   font-size: 15px;
}

.input tr {
   height: 60px;
}

.input tr th {
   background-color: #f5f5f5;
   text-align: left;
   padding-left: 28px;
   font-size: 14px;
   color: #505050;
}

.input tr td {
   padding-left: 10px;
   width: 100%;
}

.run_hist {
   width: 30% !important;
}

.input tr .table_td {
   padding-left: 10px;
   width: 43%;
}

.input.car_save_table tr .table_td {
   padding-left: 10px;
   width: 35%;
}

.car_save_date_picker {
   top: -120px;
   position: absolute;
   right: 130px;
}
.essenstial_th {
   color: #c05d5b !important;
}

.input.car_save_table .date {
   width: 200px;
}

.input.car_save_table .date_img {
   margin: 10px 10px;
}

.input.car_save_table .save_car_select {
   width: 200px;
}

.input.car_save_table .date_img:hover {
   cursor: pointer;
}

.car_save_th {
   width: 16%;
}

.info_basic {
   height: 40px;
   line-height: 40px;
   font-size: 13px;
   color: #616161;
   margin-left: 20px;
}

.info_positive {
   height: 40px;
   line-height: 40px;
   font-size: 13px;
   color: #2962ff;
   margin-left: 20px;
}

.info_negative {
   height: 40px;
   line-height: 40px;
   font-size: 13px;
   color: #e53935;
   margin-left: 20px;
}

.input_txt_number {
   text-align: right;
}

.vertical_middle {
   height: 40px;
   line-height: 40px;
}

.cost_loading_text {
   position: absolute;
   color: rgba(0, 0, 0, 0.3);
   font-size: 1em;
   z-index: 1000;
   left: 47.5%;
   bottom: 45%;
   font-weight: 500;
}

.cost_loading_text:before {
   content: attr(data-text);
   position: absolute;
   overflow: hidden;
   max-width: 12em;
   white-space: nowrap;
   color: #030000;
   animation: loading 5s linear;
}

.oilCost {
   position: absolute;
   color: rgba(0, 0, 0, 0.3);
   font-size: 1em;
   z-index: 1000;
   left: 47.5%;
   bottom: 45%;
   font-weight: 500;
}

.userList {
   overflow: auto;
   padding-top: 2px;
}

.oilCost:before {
   content: attr(data-text);
   position: absolute;
   overflow: hidden;
   max-width: 12em;
   white-space: nowrap;
   color: #030000;
   animation: loading 5s linear;
}

@keyframes loading {
   0% {
      max-width: 0;
   }
}

/*************contents***************/
.contents {
   padding-top: 40px;
}

h2 {
   font-weight: 700;
   font-size: 25px;
   margin-bottom: 30px;
}

/*table td {*/
/*    text-overflow: ellipsis;*/
/*    overflow: hidden;*/
/*    white-space: nowrap;*/
/*}*/

/*테이블*/
table.basic {
   width: 100%;
   text-align: center;
}

.table_ellipse {
   width: 100%;
   table-layout: fixed;
}

.align_right {
   text-align: right;
}

.align_left {
   text-align: left;
}

thead {
   background-color: #f5f5f5;
}

thead tr {
   height: 60px;
   border-top: 1px solid #4e4e4e;
   border-bottom: 1px solid #d0d0d0;
}

thead tr th {
   color: #4f4f4f;
}

tbody tr {
   height: 50px;
   border-bottom: 1px solid #d0d0d0;
}


/***********popup**************/
.popup {
   width: auto;
   box-sizing: border-box;
   z-index: 1000;
   animation-duration: 1s;
   position: fixed;
   left: 50%;
   animation-name: fade-in;
   transform: translateX(-50%);
   margin: auto;
   top: 50%;
   margin-top: -262px;
   /*z-index: 999999;*/
   background-color: #ffffff;
   border: 1px solid #bdbdbd;
   border-radius: 16px;
   padding: 40px 40px 20px 40px;
}

.userPop input::placeholder {
   font-size: 14px;
}

.userPop input {
   width: 190px;
}

.userPop {
   width: 270px;
   box-sizing: border-box;
   z-index: 1000;
   animation-duration: 1s;
   position: fixed;
   left: 50%;
   animation-name: fade-in;
   transform: translateX(-50%);
   top: 50%;
   /*z-index: 999999;*/
   background-color: #ffffff;
   border: 1px solid #bdbdbd;
   border-radius: 16px;
   padding: 40px 40px 20px 40px;
}

.department_arrow:hover {
   cursor: pointer;
}

.disabled_contents {
   pointer-events: none;
   opacity: 0.4;
}

textarea.write_board {
   width: 100%;
   max-width: 802px;
   height: 300px;
   box-sizing: border-box;
   border: 1px solid #d5d5d5;
   padding: 5px 8px;
   font-family: 'Noto Sans KR', serif;
   font-size: 15px;
   margin: 5px 0;
}

.popup_fade-out {
   width: auto;
   box-sizing: border-box;
   animation-duration: 1s;
   position: absolute;
   left: 50%;
   animation-name: fade-out;
   transform: translateX(-50%);
   top: 150px;
   z-index: 999999;
   background-color: #ffffff;
   border: 1px solid #bdbdbd;
   border-radius: 16px;
   padding: 40px 40px 20px 40px;
}

.user_tree_box_area {
   display: flex;
   flex-direction: row;
}

.user_tree_box_oil_cost {
   display: block;
   box-sizing: border-box;
   width: 190px;
   height: 230px;
   max-height: 350px;
   overflow: auto;
   border: 1px solid #d5d5d5;
   padding: 20px;
   margin-right: 20px;
}

.user_tree_box_oil_cost::-webkit-scrollbar {
   width: 5px;
   height: 15px;
   background-color: #e2e2e2;
   border: 1px solid #d5d5d5;
}

.user_tree_box_oil_cost::-webkit-scrollbar-corner {
   /* border: 1px solid #d5d5d5; */
   /* border-radius: 8px; */
}

.user_tree_box_oil_cost::-webkit-scrollbar-track {
   /* border: 1px solid #d5d5d5; */
   /* border-radius: 8px; */
}

.user_tree_box_oil_cost::-webkit-scrollbar-thumb {
   background: #6b00af;
}

.user_tree_box_oil_cost p:hover {
   color: #e4e7e7;
   cursor: pointer;
}

.user_tree_box {
   display: block;
   box-sizing: border-box;
   width: 220px;
   height: 400px;
   overflow: auto;
   /* border: 1px solid #d5d5d5; */
   /* border-radius: 8px; */
   padding: 20px;
   margin-right: 20px;
}

.user_tree_box::-webkit-scrollbar {
   width: 5px;
   height: 15px;
   background-color: #e2e2e2;
   border: 1px solid #d5d5d5;
   /* border-radius: 2.5px; */
}

.user_tree_box::-webkit-scrollbar-corner {
   /* border: 1px solid #d5d5d5; */
   /* border-radius: 8px; */
}

.user_tree_box::-webkit-scrollbar-track {
   /* border: 1px solid #d5d5d5; */
   /* border-radius: 8px; */
}

.user_tree_box::-webkit-scrollbar-thumb {
   background: #6b00af;
   /* border-radius: 2.5px; */
}

.user_tree_box:last-child {
   margin-left: 20px;
   margin-right: 0;
}

.user_btn_arrow_area {
   padding-top: 164px;
   box-sizing: border-box;
}

.arrow_right {
   display: block;
   box-sizing: border-box;
   width: 30px;
   height: 30px;
   border: none;
   border-radius: 8px;
   background: url(../images/btn_arrow_right.png) no-repeat center #768ba8;
   margin-bottom: 10px;
   cursor: pointer;
}

/*테이블페이지버튼*/
.page_btn li {
   min-width: 30px;
   height: 30px;
   margin: 8px;
   line-height: 30px;
   float: left;
   font-size: 14px;
   text-align: center;
   color: #263238;
}

.cursor {
   cursor: pointer;
}

.not_paging {
   color: #c1c0c0 !important;
}

.page_btn li.cursor {
   cursor: pointer;
   color: #000000;
}

.page_btn li.pageon {
   border-radius: 15px;
   background-color: #6b00af;
   color: #ffffff;
}

/** 시간 및 날짜 아이콘 숨기기 
input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
   visibility: hidden;
   -webkit-appearance: none;
}

input[type='time']::-webkit-inner-spin-button,
input[type='time']::-webkit-calendar-picker-indicator {
   visibility: hidden;
   -webkit-appearance: none;
}
*/

/** 달력 관련 로직 */
/*
.date_input {
   box-sizing: border-box;
   border-radius: 9px;
   border: 1px solid #d5d5d5;
   height: 40px;
   padding: 0 8px;
   font-family: 'Noto Sans KR', serif;
   font-size: 15px;
}

.time:hover {
   cursor: pointer;
   background-color: #80e8e0;
}

.date_input2 {
   width: 150px;
   box-sizing: border-box;
   border-radius: 9px;
   border: 1px solid #d5d5d5;
   height: 40px;
   padding: 0 8px;
   font-family: 'Noto Sans KR', serif;
   font-size: 15px;
}


.date_input2_place_holder::placeholder {
   color: black;
}
*/

.date_picker {
   position: absolute;
   right: 10em;
   top: 19em;
}

.vehicle_log_date_picker {
   position: absolute;
   left: 20px;
   top: 7em;
   margin: -1px 60px;
}

.vehicle_log_date_picker2 {
   position: absolute;
   left: 23em;
   top: 7em;
   margin: -1px 60px;
}

.vehicle_log_pop_date_picker {
   position: fixed;
   left: 40em;
   top: -2.5em;
   z-index: 2000;
}

.vehicle_log_pop_date_picker2 {
   position: fixed;
   top: 32em;
   left: 40em;
   z-index: 2000;
}

.DayPicker_transitionContainer__horizontal_2 {
   height: 27em !important;
}

/*.CalendarMonth CalendarMonth_1 {*/
/*    height: 200px !important;*/
/*}*/

.CalendarMonth_table.CalendarMonth_table_1 {
   margin: 15px 0px;
}


.CalendarMonth_table tr:last-child td {
   border: 1px solid #eee;
}

.CalendarMonth_table tr td:first-child {
   color: red;
}

.CalendarMonth_table tr td:last-child {
   color: blue;
}

/** 시간 관련 설정 */
.rc-time-picker {
   position: absolute !important;
   right: 5px;
   bottom: 35px;
}

/********************************************/

.arrow_left {
   box-sizing: border-box;
   width: 30px;
   cursor: pointer;
   height: 30px;
   border: none;
   border-radius: 8px;
   background: url(../images/btn_arrow_left.png) no-repeat center #768ba8;
}


.user_tree_box_area .user_tree_box {
   display: block;
   box-sizing: border-box;
   width: 220px;
   height: 266px;
   border: 1px solid #d5d5d5;
   /* border-radius: 8px; */
   padding: 20px;
   margin-right: 20px;
}

.user_tree_box_area > .user_btn_arrow_area {
   padding: 100px 0;
}

.user_tree_box .select_all {
   margin-left: -20px;
}



@media screen and (max-width: 1550px) {
   .vehicle_log_pop_date_picker {
      position: absolute;
      top: 60em;
      z-index: 2000;
      width: 40%;
   }

   .vehicle_log_pop_date_picker2 {
      position: absolute;
      top: 60em;
      left: 500px;
      margin-left: 5px;
      z-index: 2000;
      width: 40%;
   }
}

.vehicle_log_pop_up_time {
   left: 47.3%;
   top: 20em;
   /*z-index: 200;*/
}

.vehicle_log_pop_up_time2 {
   left: 47.3%;
   top: 40.4em;
   /*z-index: 200;*/
}


.department_underGroup_div {
   /*animation: fade-in;*/
   /*animation-duration: 1s;*/
   display: flex;
}

.animatedFadeInUp {
   opacity: 0;
}

.department_input_jnr {
   margin-right: 8px;
}

.department_p:hover {
   cursor: pointer;
   background-color: #f9efff;
}

.department_p_not_complete {
   cursor: pointer;
   background-color: #efb5b5;
}

.car_tr:hover {
   cursor: pointer;
   background-color: #f9efff;
}

.department_p {
   font-size: 15px;
}

.department_p:not(:first-child) {
   margin-top: 5px;
}

.department_div:not(:first-child) {
   margin-top: 10px;
}

.under_group_img {
   background: url('../images/baseline_subdirectory_arrow_right_black_18dp.png');
   width: 17px;
   height: 17px;
   margin-left: 3em;
}

.arrow_down {
   background: url('../images/arrow_down.png');
   width: 10px;
   height: 6px;
   /*cursor: pointer;*/
}

.delete_img {
   background: url('../images/icon_delete.png');
   width: 22px;
   height: 24px;
   margin: auto;
}

.delete_cars_user {
   background: url('../images/icon_sidebarclose.png');
   width: 13px;
   height: 13px;
   cursor: pointer;
   margin-left: 7px;
   margin-top: 1.5px;
}

.arrow_left_cars_pop_up {
   background: url('../images/btn_arrow_left.png');
   width: 10px;
   height: 6px;
   cursor: pointer;
}

.cars_popup_second_tree_div {
   display: flex;
}

.cars_popup_second_tree_div {
   margin-top: 10px;
}

.car_first_tree_box:not(:first-child) {
   margin-top: 7px !important;
}

/********/

.hms {
   display: flex;
   justify-content: space-around;
   margin-right: 0.5em;
   padding: 0.3em 0;
   font-family: 'Noto Sans KR_Bold', serif;
   font-weight: bold;
}

.user_tree_box > p {
   margin-bottom: 6px;
   margin-left: 20px;
}

.select_box > p {
   margin-bottom: 6px;
   margin-left: 20px;
}


.delete_img:hover {
   cursor: pointer;
}

.overflow_ellipsis {
   white-space: nowrap;
   overflow: hidden;
   text-overflow: ellipsis;
}



/*운행기록등록수정*/
.registration {
   width: 785px;
}

/*****************메인화면차트********************/
.ranking {
   width: 360px;
   box-sizing: border-box;
   margin-right: 10px;
}

.ranking > div {
   width: 360px;
   box-sizing: border-box;
   height: 115px;
   padding: 13px 20px 0 20px;
   background-color: #fafafa;
   border: 1px solid #d5d5d5;
   border-radius: 8px;
}

.ranking > div:first-child {
   margin-bottom: 10px;
}

.float_left {
   float: left;
}

.center {
   display: flex;
   align-items: center;
   flex-direction: column;
}

.float_right {
   float: right;
}

.chart_circle {
   width: 90px;
   height: 90px;
   /*background-color: #039be5; !*영역표시를 위해 임의로 넣은 배경은 적용시 삭제해주세요~*!*/
}

.ranking_title {
   width: auto;
   display: inline-block;
   margin-bottom: 6px;
   font-size: 16px;
   font-weight: 700;
   padding-right: 12px;
   background: url(../images/arrow_blue.png) no-repeat right center;
}

.ranking_list {
   font-size: 14px;
   line-height: 22px;
}

.barchart {
   box-sizing: border-box;
   width: 830px;
   height: 240px;
   padding: 13px 30px 0 30px;
   background-color: #fafafa;
   border: 1px solid #d5d5d5;
   border-radius: 8px;
}

.barchart > div {
   margin-right: 24px;
}

.barchart > div:last-child {
   margin-right: 0;
}

.barchart_title {
   width: auto;
   display: inline-block;
   margin-bottom: 10px;
   font-size: 16px;
   font-weight: 700;
}

.barchart_area {
   box-sizing: border-box;
   width: 240px;
   height: 172px;
   background-color: #ffffff;
   border: 1px solid #d5d5d5;
   border-radius: 8px;
}

/** 파란 바 */
.div_bar {
   width: 3px;
   height: 15px;
   background-color: #2962ff;
}

/** 수신 리스트 */
.response_list {
   display: flex;
   margin-top: -10px;
   margin-left: 20px;
}

.response_list > .response_list_text {
   margin-left: 5px;
   margin-bottom: 15px;
   font-family: 'Noto Sans KR';
   font-weight: bold;
}


/** 이름 및 닉네임 검색 (메시지관리) */
.findInput::placeholder {
   font-size: 13.5px;
}

.user_nm:hover {
   cursor: pointer;
   color: #e1e1e1;
}

/** 운행이력 거리 관련 속성 */
.run_distance {
   width: 180px !important;
}

.run_hist_text_area {
   border: 1px solid #e1e1e1;
   resize: none;
   width: 580px !important;
   height: 100px;
   margin: 10px 0;
   font-family: 'Noto Sans KR';
   font-size: 15px;
   padding: 5px 5px;
}

.run_hist_text_area::placeholder {
   font-size: 15px;
   color: #c1c1c1;
   padding: 1px 1px;
}
